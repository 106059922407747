import {OplSourceModel, OplSourceModelData} from './opl-source.model';

interface OplCordSourceModelData extends OplSourceModelData {
  hlaMismatches: string[];
  matchCategoryOption: string;
  singleCordReq: boolean;
}

export class OplCordSourceModel extends OplSourceModel {
  readonly hlaMismatches: string[];

  readonly matchCategoryOption: string;

  readonly singleCordReq: boolean;

  constructor(data: Partial<OplCordSourceModelData>) {
    super(data);

    this.hlaMismatches = data.hlaMismatches.sort(compareHlaMismatches);
    this.matchCategoryOption = data.matchCategoryOption;
    this.singleCordReq = data.singleCordReq;
  }
}

const priority: string[] = ['A', 'POS', 'A_POS'];

function compareHlaMismatches(a: string, b: string) {
  if (a.split('_')[0] !== b.split('_')[0]) {
    return a.localeCompare(b);
  }
  const priorityA = a.includes('_') ? priority.indexOf(a.substring(a.indexOf('_') + 1)) : -1;
  const priorityB = b.includes('_') ? priority.indexOf(b.substring(b.indexOf('_') + 1)) : -1;
  return priorityA - priorityB;
}
