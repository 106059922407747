import {OplSourceModel, OplSourceModelData} from './opl-source.model';

interface OplDonorSourceModelData extends OplSourceModelData {
  suggestedTypings?: string[];
}

export class OplDonorSourceModel extends OplSourceModel {
  readonly suggestedTypings: string[];

  constructor(data: Partial<OplDonorSourceModelData>) {
    super(data);

    this.suggestedTypings = (data.suggestedTypings || []).sort(compareSuggestedTypings);
  }
}

const priorityGroup: string[] = ['CT', 'CT-WU', 'CT-HR'];

function compareSuggestedTypings(a: string, b: string) {
  const aInPriorityGroup = priorityGroup.includes(a);
  const bInPriorityGroup = priorityGroup.includes(b);

  if (!aInPriorityGroup && !bInPriorityGroup) {
    return a.localeCompare(b);
  }

  const priorityA = aInPriorityGroup ? priorityGroup.indexOf(a) : 100;
  const priorityB = bInPriorityGroup ? priorityGroup.indexOf(b) : 100;

  return priorityA - priorityB;
}
