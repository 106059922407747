import {Injectable} from '@angular/core';
import {Serializer} from '@matchsource/api-utils';
import {
  OplPanelModel,
  OplDonorSourceModelClass as OplDonorSourceModel,
  OplCordSourceModelClass as OplCordSourceModel,
} from '@matchsource/models/opl';
import {OrderPreviewListDto} from '@matchsource/api-generated/orders';
import {OplOwnershipTypes} from '@matchsource/models/opl-shared';

@Injectable({
  providedIn: 'root',
})
export class OplPanelSerializerService implements Serializer<OplPanelModel, OrderPreviewListDto> {
  fromDTO(input: OrderPreviewListDto): OplPanelModel {
    if (!input) {
      return undefined;
    }

    const data = {} as OplPanelModel;
    const donors = input.donors || [];
    const cords = input.cords || [];
    const length = cords.length + donors.length;

    data.id = input.id;
    data.sharedInd = input.sharedInd;
    data.shareDate = input.shareDate;
    data.name = input.name;
    data.patientId = input.patientGuid;
    data.nameNcounter = `${data.name} (${length})`;
    data.sourceType = input.sourceType;
    data.private = !!input.private;
    data.owner = !!input.owner;
    data.noteId = input.noteId;
    data.donors = donors.map(source => new OplDonorSourceModel(source));
    data.cords = cords.map(source => new OplCordSourceModel(source));
    data.oplOwnershipType = input.oplOwnershipType;
    data.viewOnly = data.oplOwnershipType === OplOwnershipTypes.SSA && !data.private;
    data.ssaRequestId = input.ssaRequestGuid;
    data.isSSA = data.oplOwnershipType === OplOwnershipTypes.SSA;

    return data;
  }
}
