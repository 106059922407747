import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {OplDonorSerializer} from './opl-donor-serializer.service';
import {OplCordSerializer} from './opl-cord-serializer.service';
import {OplSerializer} from './opl-serializer.service';
import {OplPanelSerializerService} from './opl-panel-serializer.service';
import {
  CordOpl,
  DonorOpl,
  OrderPreviewListControllerService,
  SuggestedTypingsCriteria,
} from '@matchsource/api-generated/orders';
import {CopySourcesToOplSerializerService} from './copy-sources-to-opl-serializer.service';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {HttpContext} from '@angular/common/http';
import {
  DonorContactReportMetadataModel,
  CopySourcesToOplModel,
  CreateOplResponse,
  OplCordSimpleModel,
  OplCreateModel,
  OplDonorSimpleModel,
  OplPanelModel,
  OplPanelSimpleModel,
  OplSimpleModel,
  OplSourceTypes,
} from '@matchsource/models/opl';
import {mapDonorContactReportDtoToModel} from './donor-contact-report-serializer';
import {uniq} from '@matchsource/utils';

import {silentError} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class OplApiService {
  constructor(
    private readonly donorSerializer: OplDonorSerializer,
    private readonly cordSerializer: OplCordSerializer,
    private readonly oplSerializer: OplSerializer,
    private readonly oplPanelSerializer: OplPanelSerializerService,
    private readonly copySourcesToOplSerializerService: CopySourcesToOplSerializerService,
    private readonly orderPreviewListControllerService: OrderPreviewListControllerService
  ) {}

  private static getLoadingErrorHandler(): (context?: HttpContext) => HttpContext {
    return setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingOPLs);
  }

  private static getDeletingSourcesErrorHandler(): (context?: HttpContext) => HttpContext {
    return setSingleErrorCustomErrorHandlingContext(ClientErrorCode.DeletingOPLSources);
  }

  updateOplDonor(oplDonor: OplDonorSimpleModel, oplId: number): Observable<void> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.ModifyingOPLSource);

    return this.orderPreviewListControllerService.updateOplSource(
      {
        id: oplId,
        body: this.donorSerializer.toDTO(oplDonor),
      },
      {
        responseType: 'json',
        context: context(),
      }
    );
  }

  updateOplCord(oplCord: OplCordSimpleModel, oplId: number): Observable<void> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.ModifyingOPLSource);

    return this.orderPreviewListControllerService.updateOplSource(
      {
        id: oplId,
        body: this.cordSerializer.toDTO(oplCord),
      },
      {
        responseType: 'json',
        context: context(),
      }
    );
  }

  updateOpl(opl: OplSimpleModel): Observable<void> {
    return this.orderPreviewListControllerService.updateOpl({id: opl.id, body: this.oplSerializer.toDTO(opl)});
  }

  getOpls(recipientGuid: MsApp.Guid, sourceType: OplSourceTypes): Observable<OplPanelModel[]> {
    const context = OplApiService.getLoadingErrorHandler();

    return this.orderPreviewListControllerService
      .getOpls({recipientGuid, sourceType}, {context: context()})
      .pipe(map(data => data.map(input => this.oplPanelSerializer.fromDTO(input))));
  }

  getOpl(id: number, context?: (context?: HttpContext) => HttpContext): Observable<OplPanelModel> {
    if (!context) {
      context = OplApiService.getLoadingErrorHandler();
    }

    return this.orderPreviewListControllerService
      .getOpl({id}, {context: context()})
      .pipe(map(data => this.oplPanelSerializer.fromDTO(data)));
  }

  getOplsByIds(ids: number[], silent = true): Observable<OplPanelModel[]> {
    if (ids.length === 0) {
      return of([]);
    }

    let context: ((context?: HttpContext) => HttpContext) | undefined;

    if (silent) {
      context = silentError(true);
    }

    return forkJoin(uniq(ids).map(id => this.getOpl(id, context).pipe(catchError(() => of(null)))));
  }

  getDefaultOpl(recipientGuid: MsApp.Guid, sourceType: OplSourceTypes): Observable<OplPanelModel> {
    const context = OplApiService.getLoadingErrorHandler();

    return this.orderPreviewListControllerService
      .getDefaultOpl({recipientGuid, sourceType}, {context: context()})
      .pipe(map(data => this.oplPanelSerializer.fromDTO(data)));
  }

  saveOrUpdateDefaultOpl(id: number, recipientGuid: string, sourceType: OplSourceTypes): Observable<void> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.SavingOPLs);

    return this.orderPreviewListControllerService.saveOrUpdateDefaultOpl(
      {id, recipientGuid, sourceType},
      {context: context()}
    );
  }

  editOpl(opl: OplPanelSimpleModel): Observable<void> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.EditingOPL);

    return this.orderPreviewListControllerService.updateOpl({id: opl.id, body: opl}, {context: context()});
  }

  removeSourceFromOpl(id: number, guid: string): Observable<void> {
    const context = OplApiService.getDeletingSourcesErrorHandler();

    return this.orderPreviewListControllerService.removeSourceFromOpl({id, guid}, {context: context()});
  }

  deleteOpl(id: number): Observable<void> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.DeletingOPL);

    return this.orderPreviewListControllerService.deleteOpl({id}, {context: context()});
  }

  removeSourcesFromOpl(id: number): Observable<void> {
    const context = OplApiService.getDeletingSourcesErrorHandler();

    return this.orderPreviewListControllerService.removeSourcesFromOpl({id}, {context: context()});
  }

  createOpl(opl: OplCreateModel): Observable<CreateOplResponse> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.CreatingOPL);

    return this.orderPreviewListControllerService.createOpl({body: opl}, {context: context()});
  }

  addSourceToOPL(opl: CordOpl | DonorOpl, oplId: number): Observable<void> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.AddingOPLSource);

    return this.orderPreviewListControllerService.addSourceToOpl({id: oplId, body: opl}, {context: context()});
  }

  copySourcesToOpl(copySourcesToOpl: CopySourcesToOplModel): Observable<void> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.ModifyingOPLSource);

    return this.orderPreviewListControllerService.copySourcesToOpl(
      {
        body: this.copySourcesToOplSerializerService.toDTO(copySourcesToOpl),
      },
      {context: context()}
    );
  }

  reorderOplSource(id: number, guid: string, position: number, sourceType: 'CORD' | 'DONOR' | 'BDP'): Observable<void> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.ChangingOplSourcesOrder);

    return this.orderPreviewListControllerService.changeSourceOrder(
      {id, guid, position, sourceType},
      {responseType: 'json', context: context()}
    );
  }

  getSuggestedTypings(guid: string, body: SuggestedTypingsCriteria): Observable<DonorOpl[]> {
    return this.orderPreviewListControllerService.getSuggestedTypings({guid, body});
  }

  getDonorContactReportsByIds(reportIds: number[]): Observable<DonorContactReportMetadataModel[]> {
    if (reportIds.length === 0) {
      return of([]);
    }

    return this.orderPreviewListControllerService
      .getDonorContactReportByReportIds({
        body: uniq(reportIds),
      })
      .pipe(map(data => data.map(report => mapDonorContactReportDtoToModel(report))));
  }
}
