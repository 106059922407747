import {Injectable} from '@angular/core';
import {Serializer} from '@matchsource/api-utils';
import {CordOpl} from '@matchsource/api-generated/orders';
import {NoteTypeCodes} from '@matchsource/models/notes';
import {OplCordSimpleModel} from '@matchsource/models/opl';

@Injectable({
  providedIn: 'root',
})
export class OplCordSerializer implements Serializer<OplCordSimpleModel, CordOpl> {
  fromDTO(input: CordOpl): OplCordSimpleModel {
    return {
      guid: input.guid,
      note: {
        id: input.noteId,
        typeCode: NoteTypeCodes.OplSource,
        text: null,
      },
      singleCordReq: input.singleCordReq,
      matchCategoryOption: input.matchCategoryOption,
      hlaMismatches: input.hlaMismatches,
    };
  }

  toDTO(input: OplCordSimpleModel): CordOpl {
    return {
      guid: input.guid,
      singleCordReq: input.singleCordReq,
      matchCategoryOption: input.matchCategoryOption,
      hlaMismatches: input.hlaMismatches,
      sourceType: 'CORD',
      noteId: input.note ? input.note.id : null,
    };
  }
}
