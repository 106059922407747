import {Injectable} from '@angular/core';
import {OrderPreviewListCopySourcesDto} from '@matchsource/api-generated/orders';
import {CopySourcesToOplModel} from '@matchsource/models/opl';

@Injectable({
  providedIn: 'root',
})
export class CopySourcesToOplSerializerService {
  toDTO(input: CopySourcesToOplModel): OrderPreviewListCopySourcesDto {
    return {
      copyToId: input.copyToId,
      sourceType: input.sourceType,
      newOpl: {
        name: input.name,
        private: input.private,
        oplOwnershipType: input.oplOwnershipType,
        patientGuid: input.patientGuid,
        sourceType: input.sourceType,
      },
      sources: input.sources.map(source => ({
        noteId: source.note?.id,
        guid: source.guid,
        sourceType: input.sourceType,
        orderNum: source.orderNum ? source.orderNum : null,
      })),
    };
  }
}
