import {Injectable} from '@angular/core';
import {Serializer} from '@matchsource/api-utils';
import {DonorOpl} from '@matchsource/api-generated/orders';
import {NoteTypeCodes} from '@matchsource/models/notes';
import {OplDonorSimpleModel} from '@matchsource/models/opl';

@Injectable({
  providedIn: 'root',
})
export class OplDonorSerializer implements Serializer<OplDonorSimpleModel, DonorOpl> {
  fromDTO(input: DonorOpl): OplDonorSimpleModel {
    return {
      guid: input.guid,
      suggestedTypings: input.suggestedTypings,
      note: {
        id: input.noteId,
        typeCode: NoteTypeCodes.OplSource,
        text: null,
      },
    };
  }

  toDTO(input: OplDonorSimpleModel): DonorOpl {
    return {
      guid: input.guid,
      suggestedTypings: input.suggestedTypings,
      noteId: input.note ? input.note.id : null,
      sourceType: 'DONOR',
    };
  }
}
