import {Injectable} from '@angular/core';
import {Serializer} from '@matchsource/api-utils';
import {OrderPreviewListDto} from '@matchsource/api-generated/orders';
import {NoteTypeCodes} from '@matchsource/models/notes';
import {OplSimpleModel} from '@matchsource/models/opl';

@Injectable({
  providedIn: 'root',
})
export class OplSerializer implements Serializer<OplSimpleModel, OrderPreviewListDto> {
  fromDTO(input: OrderPreviewListDto): OplSimpleModel {
    return {
      id: input.id,
      shareDate: input.shareDate,
      name: input.name,
      patientGuid: input.patientGuid,
      sourceType: input.sourceType,
      private: input.private,
      oplOwnershipType: input.oplOwnershipType,
      note: {id: input.noteId, typeCode: NoteTypeCodes.Opl, text: ''},
    };
  }

  toDTO(input: OplSimpleModel): OrderPreviewListDto {
    return {
      id: input.id,
      shareDate: input.shareDate,
      name: input.name,
      patientGuid: input.patientGuid,
      sourceType: input.sourceType,
      private: input.private,
      oplOwnershipType: input.oplOwnershipType,
      noteId: input.note ? input.note.id : null,
    };
  }
}
