export interface OplSourceModelData {
  guid: MsApp.Guid;
  noteId: number | null;
  orderNum: number;
  source: any;
}

export class OplSourceModel {
  readonly guid: MsApp.Guid;

  readonly orderNum: number;

  readonly source: any;

  readonly noteId: number | null;

  readonly isPotentialSource: boolean;

  constructor(data: Partial<OplSourceModelData>) {
    this.guid = data.guid;
    this.orderNum = data.orderNum;
    this.source = data.source || null;
    this.noteId = data.noteId;
    this.isPotentialSource = !data.source;
  }
}
