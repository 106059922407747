import {MapTo} from '@matchsource/api-utils';
import {OplReportsDto} from '@matchsource/api-generated/orders';
import {DonorContactReportMetadataModel} from '@matchsource/models/opl';

export const mapDonorContactReportDtoToModel: MapTo<OplReportsDto, DonorContactReportMetadataModel> = (
  dto: OplReportsDto
) => ({
  id: dto.id,
  oplId: dto.oplId,
  documentId: dto.owcDocumentId,
  fileName: dto.fileName,
  reportDate: dto.reportDate,
});
